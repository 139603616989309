import React from "react"
import { Link } from "gatsby"

import Header from "../components/Header.js"
import Footer from "../components/Footer.js"
import Seo from "../components/Seo"

function importAll(r) {
	return r.keys().map(r)
}

const emojisList = importAll(
	require.context("../content/emojis/", false, /\.(png|jpe?g|svg)$/)
)

function Page() {
	return (
		<div>
			<Seo
				pathname="/best-emojis"
				title="Best Custom Emojis"
				description="Most loved custom emojis created using Emoji Maker"
			/>
			<Header />
			<main>
				<div className="mx-auto container pt-16 sm:pt-32 pb-10 sm:pb-24">
					<div className="px-6 mx-auto max-w-5xl">
						<h1 className="pb-6 text-5xl sm:text-6xl font-bold leading-none sm:leading-tighter tracking-tighter sm:text-center">
							Best Custom Emojis
						</h1>
						<div className="max-w-xl mx-auto pb-16 text-xl sm:text-2xl text-gray-700 sm:text-center leading-snug sm:tracking-snug">
							<p className="pb-4">
								Most loved custom emojis created using our{" "}
								<Link className="text-blue-500" to="/">
									Emoji Maker
								</Link>
								.
							</p>
							<a href="https://flat-icons.com/">
								<div
									className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
									role="alert"
								>
									{/* <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
										New
									</span> */}
									<span className="font-semibold mx-2 text-left flex-auto text-md sm:text-lg">
										Irresistible Flat Icons - Free & Premium Icon Sets
									</span>
									<svg
										className="fill-current opacity-75 h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
									>
										<path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
									</svg>
								</div>
							</a>
							{/* <p>
								Checkout our{' '}
								<a
									className='text-blue-500'
									href='https://teespring.com/stores/jamstackstudio'
								>
									emoji merch store 👕
								</a>
							</p> */}
						</div>
						<div className="mx-auto max-w-4xl">
							<div className="w-full flex flex-wrap justify-center">
								{emojisList.map((emoji, key) => (
									<a
										className="w-56 m-8 hover:bg-gray-200 rounded"
										key={key}
										href={emoji}
										download
									>
										<img src={emoji} alt="Custom Emoji by Emoji Maker" />
									</a>
								))}
							</div>
						</div>
					</div>
				</div>
			</main>
			{/* <Footer /> */}
		</div>
	)
}

export default Page
